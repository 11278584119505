import React from "react";
import { graphql, PageProps } from "gatsby";

import { SpotlightList } from "components/customers";
import { BlogSection } from "components/blog";
import SEO from "components/SEO";
import pagesMeta from "data/pagesMeta";
import { extractCategories } from "helpers/utils/generics";

const AllBlogPage: React.FC<PageProps> = ({ data, location }) => {
  const {
    posts: { edges: posts },
    spotlights: { edges: spotlights },
  } = data as any;

  const categories = extractCategories(posts[0].node.data, "/blog/category");

  return (
    <>
      <SEO {...pagesMeta.blog} />
      <BlogSection
        sections={categories}
        posts={posts[0].node.data}
        location={location}
      />
      <SpotlightList
        spotlights={spotlights[0].node.data as any}
        theme="light"
      />
    </>
  );
};

export const pageQuery = graphql`
  query AllBlogPage {
    posts: allStrapiBlog {
      edges {
        node {
          id
          data {
            attributes {
              content
              description
              featuredPost
              date
              slug
              title
              featuredImage {
                data {
                  attributes {
                    ext
                    url
                    localFile {
                      childImageSharp {
                        gatsbyImageData(width: 480, layout: CONSTRAINED)
                      }
                    }
                  }
                }
              }
              category {
                data {
                  id
                  attributes {
                    name
                    slug
                  }
                }
              }
            }
          }
        }
      }
    }
    spotlights: allStrapiSpotlight {
      edges {
        node {
          data {
            id
            attributes {
              description
              title
              url
              poster {
                data {
                  attributes {
                    localFile {
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default AllBlogPage;
